import React, { useState } from 'react';
import toast from 'react-hot-toast';
import android from "../images/android.png";
import ios from "../images/ios.png";
import addIcon from "../images/add-icon.png";
import shareIcon from "../images/share-icon.png";
import shareIconAndroid from "../images/share-icon-android.png";
import googleplay from "../images/google-play.png";
import appstore from "../images/app-store.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPlusSquare, faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const [iosShowModal, setIosShowModal] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const iosHandleModalOpen = () => {
        setIosShowModal(true);
    };

    const iosHandleModalClose = () => {
        setIosShowModal(false);
    };
    const notifyError = () => toast.error('Recurso disponível em breve.', {
        position: "top-center"
      });
    return (
        <>
            <div className="home-container bg-light">
                <h3 style={{color:'#E01366'}}>Seja bem vinda amiga! 🎁🥰</h3>
                <div className="button-container">
                    <button className="pink-button rounded"><a href='/dashboard' style={{textDecoration:'none', color:'white'}}>PROMOÇÕES DE HOJE</a></button>
                    <button onClick={notifyError} className="pink-button rounded">NOVIDADES DE HOJE</button>
                    <button className="pink-button rounded"><a href='https://www.instagram.com/madamycloset/' style={{textDecoration:'none', color:'white'}}>NOSSO INSTAGRAM</a></button>
                    <button className="pink-button rounded"><a href='https://pariscloset.vip/ ' style={{textDecoration:'none', color:'white'}}>NOSSO SITE</a></button>
                </div>
                <div>
                <center>
                    <h7 className="flex-wrap" style={{ fontWeight: 'bolder', color:'#E01366' }}>
                        Para uma melhor experiência,<br />
                        <span>instale nosso app</span>
                    </h7>
                </center>
                    <center
                        style={{
                            marginRight:'1rem'
                        }}
                    >

                        <img
                        className="rounded mt-3 m-3"
                        src={googleplay}
                        style={{ 
                            width: '9rem', 
                            height:'2.7rem', 
                            cursor: 'pointer',
                        }}
                        onClick={handleModalOpen}
                        />
                        <img
                            className="rounded"
                            src={appstore}
                            style={{ width: '9rem',marginTop:'' , height:'2.7rem', cursor: 'pointer' }}
                            onClick={iosHandleModalOpen}
                        />
                    </center>
                    <Modal
                        show={showModal}
                        onHide={handleModalClose}
                        centered
                        style={{ maxWidth: '90%', marginLeft: '5%' }}
                        >
                            <Modal.Header
                                style={{ 
                                background: 'white', 
                                borderTop: '2px solid #E01366',
                                borderLeft: '2px solid #E01366',
                                borderRight: '2px solid #E01366',
                                borderBottom: '0 solid black',
                                }}
                                closeButton
                            >
                                <Modal.Title style={{ color: 'black', marginLeft:'2rem' }}>
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div>
                                    <img src={android} style={{ width: '10rem' }} />
                                    <h5 className="col-12 mb-0 d-flex text-center justify-content-center" style={{ color:'#E01366'}}>
                                        Instale o nosso app
                                    </h5>
                                    </div>
                                </div>
                                <small 
                                className="text-black d-flex justify-content-center text-center"
                                style={{fontSize:'0.8rem', marginTop:'0.4rem'}}
                                >
                                    Clique na janela que aparecerá no rodapé da página ou clique em adicione o App à tela inicial do seu smartphone
                                </small>
                                <h6 
                                className="d-flex justify-content-center text-center" 
                                style={{ color:'#E01366', marginTop:'1rem'}}
                                >
                                    Android (Google Chrome)
                                </h6>
                                <div 
                                className="rounded col-12 d-flex justify-content-center text-center"
                                style={{backgroundColor:'#E01366', color:'black', paddingTop:'0.3rem', paddingBottom:'0.3rem'}}
                                >
                                    <small className="col-10 text-white" style={{fontSize:'1rem', marginTop:'0.3rem'}}>INSTALAR APLICATIVO</small>
                                    <img src={shareIconAndroid} style={{width:'1.8rem', height:'1.8rem'}}/>
                                </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body 
                                style={{ 
                                background: 'white',
                                borderTop: '2px solid white', 
                                borderBottom: '2px solid #E01366',
                                borderLeft: '2px solid #E01366',
                                borderRight: '2px solid #E01366',
                                }}
                            >
                            </Modal.Body>
                        </Modal>

                    <Modal
                    show={iosShowModal}
                    onHide={iosHandleModalClose}
                    centered
                    style={{ maxWidth: '90%', marginLeft: '5%'}}
                    >
                        <Modal.Header
                            style={{ 
                            background: 'white', 
                            borderTop: '2px solid #E01366',
                            borderLeft: '2px solid #E01366',
                            borderRight: '2px solid #E01366',
                            borderBottom: '0 solid black',
                            }}
                            closeButton
                        >
                            <Modal.Title style={{ color: 'black' }}>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <div>
                                <img src={ios} style={{ width: '5rem', marginLeft:'10%' }} />
                                </div>
                                <div>
                                </div>
                                <h5 className="col-9" style={{marginLeft:'10%', color:'#E01366'}}>
                                    Instale o nosso app
                                </h5>
                                <small className="col-10 justify-content-center text-center" style={{marginLeft:'10%', fontSize:'0.9rem'}}>Siga as etapas abaixo e instale o nosso app em seu dispositivo</small>
                                <small style={{color:'#E01366'}}>iOS (Safari)</small>
                            </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body 
                            style={{ 
                            background: 'white',
                            borderTop: '2px solid black', 
                            borderBottom: '2px solid #E01366',
                            borderLeft: '2px solid #E01366',
                            borderRight: '2px solid #E01366',
                            }}
                        >
                            <div className="col-12 row" style={{marginLeft:'3%'}}>
                                <div className="col-2 rounded" style={{border:'2px solid #E01366'}}>
                                    <h3 
                                        style={{ 
                                        marginTop:'4px', 
                                        marginLeft:'-4px', 
                                        marginRight:'0px',
                                        color:'#E01366'
                                        }}
                                    >
                                    01
                                    </h3>
                                </div>
                                <small className="col-7 text-black" style={{fontSize:'0.7rem'}}>Clique no símbolo de compartilhar do navegador</small>
                                <div className="col-2">
                                    <FontAwesomeIcon icon={faArrowUpFromBracket} size='2x' style={{ color: '#E01366', marginRight: '0.3rem', marginLeft:'0.1rem' }} />
                                    {/* <img src={shareIcon} style={{marginRight:'0.1rem' ,width:'2rem', backgroundColor:'#E01366'}} /> */}
                                </div>
                            </div>
                            <div className="col-12 row mt-2" style={{marginLeft:'3%'}}>
                                <div className="col-2 rounded" style={{border:'2px solid #E01366'}}>
                                    <h3 
                                        style={{ 
                                        marginTop:'4px', 
                                        marginLeft:'-4px', 
                                        marginRight:'2px',
                                        color:'#E01366'
                                        }}
                                    >
                                    02
                                    </h3>
                                </div>
                                <small className="col-7 text-black" style={{fontSize:'0.7rem'}}>Procure o botão "Adicionar à Tela de início"</small>
                                <div className="col-2" 
                                // style={{backgroundColor:'#E01366'}}
                                >
                                <FontAwesomeIcon icon={faPlusSquare} size='2x' style={{ color: '#E01366', marginRight: '0.3rem', marginLeft:'0.1rem' }} />
                                    {/* <img src={addIcon} style={{marginLeft:'0.2rem' , width:'1.7rem'}} /> */}
                                </div>
                            </div>
                            <div className="col-12 row mt-2" style={{marginLeft:'3%'}}>
                                <div className="col-2 rounded" style={{border:'2px solid #E01366'}}>
                                    <h3 
                                        style={{ 
                                        marginTop:'4px', 
                                        marginLeft:'-4px', 
                                        marginRight:'2px',
                                        color:'#E01366'
                                        }}
                                    >
                                    03
                                    </h3>
                                </div>
                                <small className="col-6 text-black" style={{fontSize:'0.7rem'}}>Adicione o app à tela de início</small>
                                <div className="col-2" style={{}}>
                                    <small 
                                    className="rounded" 
                                    style={{
                                        marginLeft:'0.5rem',
                                        fontSize:'0.8rem',
                                        color:'white', 
                                        borderTop:'0.1rem solid white', 
                                        paddingTop:'0.2rem', 
                                        paddingBottom:'0.3rem', 
                                        paddingLeft:'0.3rem', 
                                        paddingRight:'0.3rem', 
                                        backgroundColor:'#E01366'
                                        }}>
                                            Adicionar
                                    </small>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default Home;
