import React, { useState } from 'react';
import { Container, Form, InputGroup ,Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import countryCodes from '../components/countries/Countries';
import BrandCheckboxDropdown from '../components/DropDown/BrandCheckboxDropdown';
import SelectedBrandsLabel from '../components/DropDown/SelectedBrandsLabel';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { userLogin } from '../redux/authSlice';
import toast from 'react-hot-toast';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


import axios from 'axios';

const brandOptions = [
    'Louis Vuitton', 'Gucci',
    'Chanel', 'Hermes',
    'Dior', 'Prada',
    'Versace', 'Balenciaga',
    'Fendi', 'YSL',
    'Valentino', 'Dolce Gabbana',
    'Bottega', 'Celine',
    'Chloe', 'MiuMiu',
    'Goyard',
];

const notifySuccess = () => toast.success('Registro realizado com sucesso!!!', {
    position: "top-center"
  });

const notifyError = (error) => toast.error(`${error}`, {
    position: "top-center"
  });
const Register = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [token, setToken] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [insta, setInsta] = useState('');
    const [countryCode, setCountryCode] = useState(Object.keys(countryCodes)[0]); // define o valor inicial
    const [ddd, setDdd] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [gender, setGender] = useState('');
    const [date, setDate] = useState('');
    const [produtoPromo, setProdutoPromo] = useState('');
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Validar se 3 marcas foram selecionadas
        if (selectedBrands.length < 3) {
          alert('Por favor, selecione pelo menos 3 marcas.');
          return;
        }
      
        // Montar os dados do formulário
        const formData = {
            name,
            email,
            insta,
            date,
            produtoPromo,
            countryCode,
            ddd,
            gender,
            phoneNumber,
            selectedBrands,
            selectedOption,
          };          
      
        try {
            const response = await axios.post('https://api-web-stories.vercel.app/register', 
            // const response = await axios.post('http://localhost:8000/register', 
            // baseURL:'http://localhost:8000/',
            formData,
            {
                headers: {
                    'Content-Type': 'application/json', // Definir o cabeçalho Content-Type
                },
            }
            );
            const { token } = response.data;
            setToken(token);
            console.log('Formulário enviado com sucesso:', response.data);
            notifySuccess();
            const body = {
                email
                // password: formik.values.password,
              };
            dispatch(userLogin(body)).then((data) => {
            if (data.payload === "undefined" || !data.payload) return;
            // dispatch(getUserData());
            navigate("/home");
              });
            navigate("/home");
        } catch (error) {
            if (error.response) {
                // A resposta contém detalhes sobre o erro
                const { data } = error.response;
                // Aqui você pode notificar o usuário com os detalhes do erro
                notifyError(data.detail);
              }
        }
    };
    
    const isRegistrationValid = () => {
        return (
          name !== '' &&
          email !== '' &&
          insta !== '' &&
          countryCode !== '' &&
          ddd !== '' &&
          phoneNumber !== '' &&
          selectedBrands.length > 2 &&
          selectedOption !== '' &&
          date !== '' &&
          produtoPromo !== '' &&
          gender !== ''
        );
      };
      
    const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
    };

    
    const handleDddChange = (event) => {
    setDdd(event.target.value);
    };
    
    const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };
    const formatDate = (input) => {
        // Remove qualquer caracter não numérico
        const cleanedInput = input.replace(/\D/g, '');
      
        // Separa a data em dia, mês e ano
        const day = cleanedInput.slice(0, 2);
        const month = cleanedInput.slice(2, 4);
        const year = cleanedInput.slice(4, 8);
      
        // Formata a data como DD/MM/AAAA
        const formattedDate = `${day}/${month}/${year}`;
      
        return formattedDate;
      };
    const handleDateChange = (event) => {
        const inputDate = event.target.value;
        const formattedDate = formatDate(inputDate);
        setDate(formattedDate);
    };
    const handleProdutoPromo = (event) => {
        setProdutoPromo(event.target.value);
    }
    const brandRows = [];
    for (let i = 0; i < brandOptions.length; i += 2) {
    brandRows.push(brandOptions.slice(i, i + 2));
    }
    const handleBrandChange = (brand) => {
        if (selectedBrands.includes(brand)) {
          setSelectedBrands(selectedBrands.filter((selectedBrand) => selectedBrand !== brand));
        } else {
          setSelectedBrands([...selectedBrands, brand]);
        }
    };
      
   
    return(
        <Container>
                <div style={{ width: "100%", height: "0", paddingBottom: "100%", position: "relative",  marginBottom:'1.5rem' }}>
                <div>
                    <a href="/">
                        <FontAwesomeIcon icon={faArrowCircleLeft} size='2x' style={{ color: '#E01366', marginLeft: '0.1rem', marginTop:'0.5rem' }} />
                    </a>
                </div>
                <iframe
                    src="https://giphy.com/embed/8ZzPIFi9Dqv5cqJkDE"
                    width="100%"
                    height="100%"
                    style={{ position: "absolute" }}
                    frameBorder="0"
                    class="giphy-embed"
                    allowFullScreen
                    sandbox="allow-same-origin allow-scripts"
                ></iframe>
                </div>
                <h2 className='d-flex border rounded text-white justify-content-center text-center align-items-center' style={{backgroundColor:'#E01366'}}>Para ter acesso às promoções, preencha esse formulário!</h2>
            <div className='form-register mb-4 border rounded d-flex justify-content-center align-items-center'>
                <Form className='col-10 ' onSubmit={handleSubmit}>
                    <h4 className='text-center mt-4'>Queremos saber mais de você!</h4>
                    <Form.Group controlId='formRegister'>
                        <Form.Label>Qual seu nome?</Form.Label>
                        <Form.Control
                        type='text'
                        placeholder='Nome completo'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Label>Email para acessar nosso site site vip</Form.Label>
                        <Form.Control
                        type='email'
                        placeholder='seu@email.com'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />

                        <Form.Label>Insira seu número correto de WhatsApp</Form.Label>
                        <InputGroup className='mb-3'>
                            <Form.Control as='select' style={{ width: '25%' }}>
                                {Object.keys(countryCodes).map((code) => (
                                <option key={code} value={code}>
                                    {code} {countryCodes[code]}
                                </option>
                                ))}
                            </Form.Control>
                            <Form.Control
                                className='col-2'
                                type='text'
                                style={{ width: '25%' }}
                                placeholder='DDD'
                                value={ddd}
                                onChange={handleDddChange}
                            />
                            <Form.Control
                                className='col-8'
                                type='text'
                                style={{ width: '50%' }}
                                placeholder='Seu Número'
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                            />
                        </InputGroup>


                        <Form.Label>@ do seu instagram</Form.Label>
                        <Form.Control
                        type='text'
                        value={insta}
                        onChange={(e) => setInsta(e.target.value)}
                        />

                        <Form.Label>As 3 marcas que você mais gosta</Form.Label>
                        <BrandCheckboxDropdown
                        brandOptions={brandOptions}
                        selectedBrands={selectedBrands}
                        handleBrandChange={handleBrandChange}
                        />
                        <SelectedBrandsLabel selectedBrands={selectedBrands} />

                        <Form.Group controlId='radioGroup'>
                            <Form.Label>Você já tem alguma bolsa de griffe?</Form.Label>
                            <div>
                                <Form.Check
                                    type='radio'
                                    label='Ainda não'
                                    name='radioOption'
                                    value='Ainda não'
                                    checked={selectedOption === 'Ainda não'}
                                    onChange={handleOptionChange}
                                />
                            </div>
                            <div>
                                <Form.Check
                                    type='radio'
                                    label='Tenho uma'
                                    name='radioOption'
                                    value='Tenho uma'
                                    checked={selectedOption === 'Tenho uma'}
                                    onChange={handleOptionChange}
                                />
                            </div>
                            <div>
                                <Form.Check
                                    type='radio'
                                    label='Tenho várias, amo!'
                                    name='radioOption'
                                    value='Tenho várias, amo'
                                    checked={selectedOption === 'Tenho várias, amo'}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group controlId='dateInput'>
                            <Form.Label>Seu aniversário (adoramos dar presente para os clientes)</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='DD/MM/AAAA'
                                value={date}
                                onChange={handleDateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId='radioGroup'>
                            <Form.Label>Gênero</Form.Label>
                            <div>
                                <Form.Check
                                    type='radio'
                                    label='Feminino'
                                    name='radioOption2'
                                    value='feminino'
                                    checked={gender === 'feminino'}
                                    onChange={handleGenderChange}
                                />
                            </div>
                            <div>
                                <Form.Check
                                    type='radio'
                                    label='Masculino'
                                    name='radioOption2'
                                    value='masculino'
                                    checked={gender === 'masculino'}
                                    onChange={handleGenderChange}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Quais produtos você quer na promo?</Form.Label>
                        <Form.Control
                            type='text'
                            value={produtoPromo}
                            onChange={handleProdutoPromo}
                        />
                    </Form.Group>
                    <div className='d-flex mt-3 mb-3 justify-content-center align-items-center'>
                        <Button className='btn-success' onClick={handleSubmit} disabled={!isRegistrationValid()}>
                            QUERO A PROMO
                        </Button>
                    </div>
                </Form>
            </div>
        </Container>
    );
}

export default Register;