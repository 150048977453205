import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [activeButton, setActiveButton] = useState('');

  const handleButtonClick = (href) => {
    setActiveButton(href);
  };

  return (
    <AppContext.Provider value={{ activeButton, handleButtonClick }}>
      {children}
    </AppContext.Provider>
  );
};
