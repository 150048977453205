import React, { useEffect, useState } from "react";
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import { resetState, userLogin } from '../redux/authSlice';
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { loginSchema } from "../validation/Schema";
import { getUserData, resetDashboardState } from "../redux/DashboardSlice";
import { Modal } from 'react-bootstrap';
const Login = () => {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const formik = useFormik({
        initialValues: {
          email: "",
          // password: "",
        },
        onSubmit: (values) => {
          setLoading(true)
          const body = {
            email: formik.values.email,
            // password: formik.values.password,
          };
          dispatch(userLogin(body)).then((data) => {
            try{
              if (data.payload === "undefined" || !data.payload) return;
              // dispatch(getUserData());
              navigate("/home");
            } catch(err) {
              console.log('O email informado não possui acesso.')
            } finally {
              setLoading(false);
            }
          });
        },
      });
    
      useEffect(() => {
        dispatch(resetState());
        dispatch(resetDashboardState());
      }, [dispatch]);


    return(
        <Container className="d-flex justify-content-center align-items-center vh-100">
            <div>
                <h2 className="mb-3 text-center">Sua Personal Shopper</h2>
                <div style={{ width: "100%", height: "0", paddingBottom: "100%", position: "relative" }}>
                <iframe
                    src="https://giphy.com/embed/8ZzPIFi9Dqv5cqJkDE"
                    width="100%"
                    height="100%"
                    style={{ position: "absolute" }}
                    frameBorder="0"
                    class="giphy-embed"
                    allowFullScreen
                    sandbox="allow-same-origin allow-scripts"
                ></iframe>
                </div>
                
                <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="email">
                    <Form.Label className='d-flex align-items-center justify-content-center'>Email de acesso</Form.Label>
                    <Form.Control
                    type="text"
                    label="Email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email.toLocaleLowerCase()}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <p>{formik.errors.email}</p>
                    ) : null}
                    <small className='d-flex align-items-center justify-content-center'>
                        Ainda não tem conta? <a href='/register' style={{marginLeft:'0.3rem'}}>Cadastre-se aqui</a>
                    </small>
                </Form.Group>
                {loading ? (
                  <div className="text-center mt-3">
                    <div className="spinner-border" role="status" style={{ color: '#05d9e8' }}>
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="text-center mt-3">
                    <Button className='col-6' variant="danger" type="submit">
                        Entrar
                    </Button>
                  </div>
                )}
                </Form>
            </div>
        </Container>
    )
}

export default Login;