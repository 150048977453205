import React from 'react';
import { Form } from 'react-bootstrap';

const SelectedBrandsLabel = ({ selectedBrands }) => {
  return (
    <Form.Group controlId='selectedBrandsLabel'>
      <Form.Label style={{color:'green'}}>Marcas escolhidas: {selectedBrands.join(', ')}</Form.Label>
    </Form.Group>
  );
};

export default SelectedBrandsLabel;
