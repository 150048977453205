import React, { useState } from 'react';
import { Form, Dropdown, Row, Col } from 'react-bootstrap';

const BrandCheckboxDropdown = ({ brandOptions, selectedBrands, handleBrandChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Form.Group controlId='checkboxBrands'>
      <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
        <Dropdown.Toggle variant='danger' id='brandDropdown'>
          Selecione as marcas
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Row>
            {brandOptions.map((brand, index) => (
              <Col className='m-2' key={index} xs={6}>
                <Form.Check
                  type='checkbox'
                  label={brand}
                  checked={selectedBrands.includes(brand)}
                  onChange={() => handleBrandChange(brand)}
                />
              </Col>
            ))}
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
};

export default BrandCheckboxDropdown;