const countryCodes = {
    "+55": "\ud83c\udde7\ud83c\uddf7",
    "+1": "\ud83c\uddfa\ud83c\uddf8",
    "+7": "\ud83c\uddf7\ud83c\uddfa",
    "+20": "\ud83c\uddea\ud83c\uddec",
    "+27": "\ud83c\uddff\ud83c\udde6",
    "+30": "\ud83c\uddec\ud83c\uddf7",
    "+31": "\ud83c\uddf3\ud83c\uddf1",
    "+32": "\ud83c\udde7\ud83c\uddea",
    "+33": "\ud83c\uddeb\ud83c\uddf7",
    "+34": "\ud83c\uddea\ud83c\uddf8",
    "+36": "\ud83c\udded\ud83c\uddfa",
    "+39": "\ud83c\uddee\ud83c\uddf9",
    "+40": "\ud83c\uddf7\ud83c\uddf4",
    "+41": "\ud83c\udde8\ud83c\udded",
    "+43": "\ud83c\udde6\ud83c\uddf9",
    "+44": "\ud83c\uddef\ud83c\uddea",
    "+45": "\ud83c\udde9\ud83c\uddf0",
    "+46": "\ud83c\uddf8\ud83c\uddea",
    "+47": "\ud83c\uddf8\ud83c\uddef",
    "+48": "\ud83c\uddf5\ud83c\uddf1",
    "+49": "\ud83c\udde9\ud83c\uddea",
    "+51": "\ud83c\uddf5\ud83c\uddea",
    "+52": "\ud83c\uddf2\ud83c\uddfd",
    "+53": "\ud83c\udde8\ud83c\uddfa",
    "+54": "\ud83c\udde6\ud83c\uddf7",
    "+56": "\ud83c\udde8\ud83c\uddf1",
    "+57": "\ud83c\udde8\ud83c\uddf4",
    "+58": "\ud83c\uddfb\ud83c\uddea",
    "+60": "\ud83c\uddf2\ud83c\uddfe",
    "+61": "\ud83c\udde8\ud83c\uddfd",
    "+62": "\ud83c\uddee\ud83c\udde9",
    "+63": "\ud83c\uddf5\ud83c\udded",
    "+64": "\ud83c\uddf5\ud83c\uddf3",
    "+65": "\ud83c\uddf8\ud83c\uddec",
    "+66": "\ud83c\uddf9\ud83c\udded",
    "+81": "\ud83c\uddef\ud83c\uddf5",
    "+82": "\ud83c\uddf0\ud83c\uddf7",
    "+84": "\ud83c\uddfb\ud83c\uddf3",
    "+86": "\ud83c\udde8\ud83c\uddf3",
    "+90": "\ud83c\uddf9\ud83c\uddf7",
    "+91": "\ud83c\uddee\ud83c\uddf3",
    "+92": "\ud83c\uddf5\ud83c\uddf0",
    "+93": "\ud83c\udde6\ud83c\uddeb",
    "+94": "\ud83c\uddf1\ud83c\uddf0",
    "+95": "\ud83c\uddf2\ud83c\uddf2",
    "+98": "\ud83c\uddee\ud83c\uddf7",
    "+211": "\ud83c\uddf8\ud83c\uddf8",
    "+212": "\ud83c\uddf2\ud83c\udde6",
    "+213": "\ud83c\udde9\ud83c\uddff",
    "+216": "\ud83c\uddf9\ud83c\uddf3",
    "+218": "\ud83c\uddf1\ud83c\uddfe",
    "+220": "\ud83c\uddec\ud83c\uddf2",
    "+221": "\ud83c\uddf8\ud83c\uddf3",
    "+222": "\ud83c\uddf2\ud83c\uddf7",
    "+223": "\ud83c\uddf2\ud83c\uddf1",
    "+224": "\ud83c\uddec\ud83c\uddf3",
    "+225": "\ud83c\udde8\ud83c\uddee",
    "+226": "\ud83c\udde7\ud83c\uddeb",
    "+227": "\ud83c\uddf3\ud83c\uddea",
    "+228": "\ud83c\uddf9\ud83c\uddec",
    "+229": "\ud83c\udde7\ud83c\uddef",
    "+230": "\ud83c\uddf2\ud83c\uddfa",
    "+231": "\ud83c\uddf1\ud83c\uddf7",
    "+232": "\ud83c\uddf8\ud83c\uddf1",
    "+233": "\ud83c\uddec\ud83c\udded",
    "+234": "\ud83c\uddf3\ud83c\uddec",
    "+235": "\ud83c\uddf9\ud83c\udde9",
    "+236": "\ud83c\udde8\ud83c\uddeb",
    "+237": "\ud83c\udde8\ud83c\uddf2",
    "+238": "\ud83c\udde8\ud83c\uddfb",
    "+239": "\ud83c\uddf8\ud83c\uddf9",
    "+240": "\ud83c\uddec\ud83c\uddf6",
    "+241": "\ud83c\uddec\ud83c\udde6",
    "+242": "\ud83c\udde8\ud83c\uddec",
    "+243": "\ud83c\udde8\ud83c\udde9",
    "+244": "\ud83c\udde6\ud83c\uddf4",
    "+245": "\ud83c\uddec\ud83c\uddfc",
    "+246": "\ud83c\uddee\ud83c\uddf4",
    "+248": "\ud83c\uddf8\ud83c\udde8",
    "+249": "\ud83c\uddf8\ud83c\udde9",
    "+250": "\ud83c\uddf7\ud83c\uddfc",
    "+251": "\ud83c\uddea\ud83c\uddf9",
    "+252": "\ud83c\uddf8\ud83c\uddf4",
    "+253": "\ud83c\udde9\ud83c\uddef",
    "+254": "\ud83c\uddf0\ud83c\uddea",
    "+255": "\ud83c\uddf9\ud83c\uddff",
    "+256": "\ud83c\uddfa\ud83c\uddec",
    "+257": "\ud83c\udde7\ud83c\uddee",
    "+258": "\ud83c\uddf2\ud83c\uddff",
    "+260": "\ud83c\uddff\ud83c\uddf2",
    "+261": "\ud83c\uddf2\ud83c\uddec",
    "+262": "\ud83c\uddfe\ud83c\uddf9",
    "+263": "\ud83c\uddff\ud83c\uddfc",
    "+264": "\ud83c\uddf3\ud83c\udde6",
    "+265": "\ud83c\uddf2\ud83c\uddfc",
    "+266": "\ud83c\uddf1\ud83c\uddf8",
    "+267": "\ud83c\udde7\ud83c\uddfc",
    "+268": "\ud83c\uddf8\ud83c\uddff",
    "+269": "\ud83c\uddf0\ud83c\uddf2",
    "+290": "\ud83c\uddf8\ud83c\udded",
    "+291": "\ud83c\uddea\ud83c\uddf7",
    "+297": "\ud83c\udde6\ud83c\uddfc",
    "+298": "\ud83c\uddeb\ud83c\uddf4",
    "+299": "\ud83c\uddec\ud83c\uddf1",
    "+345": "\ud83c\uddf0\ud83c\uddfe",
    "+350": "\ud83c\uddec\ud83c\uddee",
    "+351": "\ud83c\uddf5\ud83c\uddf9",
    "+352": "\ud83c\uddf1\ud83c\uddfa",
    "+353": "\ud83c\uddee\ud83c\uddea",
    "+354": "\ud83c\uddee\ud83c\uddf8",
    "+355": "\ud83c\udde6\ud83c\uddf1",
    "+356": "\ud83c\uddf2\ud83c\uddf9",
    "+357": "\ud83c\udde8\ud83c\uddfe",
    "+358": "\ud83c\uddeb\ud83c\uddee",
    "+359": "\ud83c\udde7\ud83c\uddec",
    "+370": "\ud83c\uddf1\ud83c\uddf9",
    "+371": "\ud83c\uddf1\ud83c\uddfb",
    "+372": "\ud83c\uddea\ud83c\uddea",
    "+373": "\ud83c\uddf2\ud83c\udde9",
    "+374": "\ud83c\udde6\ud83c\uddf2",
    "+375": "\ud83c\udde7\ud83c\uddfe",
    "+376": "\ud83c\udde6\ud83c\udde9",
    "+377": "\ud83c\uddf2\ud83c\udde8",
    "+378": "\ud83c\uddf8\ud83c\uddf2",
    "+379": "\ud83c\uddfb\ud83c\udde6",
    "+380": "\ud83c\uddfa\ud83c\udde6",
    "+381": "\ud83c\uddf7\ud83c\uddf8",
    "+382": "\ud83c\uddf2\ud83c\uddea",
    "+383": "\ud83c\uddfd\ud83c\uddf0",
    "+385": "\ud83c\udded\ud83c\uddf7",
    "+386": "\ud83c\uddf8\ud83c\uddee",
    "+387": "\ud83c\udde7\ud83c\udde6",
    "+389": "\ud83c\uddf2\ud83c\uddf0",
    "+420": "\ud83c\udde8\ud83c\uddff",
    "+421": "\ud83c\uddf8\ud83c\uddf0",
    "+423": "\ud83c\uddf1\ud83c\uddee",
    "+500": "\ud83c\uddec\ud83c\uddf8",
    "+501": "\ud83c\udde7\ud83c\uddff",
    "+502": "\ud83c\uddec\ud83c\uddf9",
    "+503": "\ud83c\uddf8\ud83c\uddfb",
    "+504": "\ud83c\udded\ud83c\uddf3",
    "+505": "\ud83c\uddf3\ud83c\uddee",
    "+506": "\ud83c\udde8\ud83c\uddf7",
    "+507": "\ud83c\uddf5\ud83c\udde6",
    "+508": "\ud83c\uddf5\ud83c\uddf2",
    "+509": "\ud83c\udded\ud83c\uddf9",
    "+590": "\ud83c\uddf2\ud83c\uddeb",
    "+591": "\ud83c\udde7\ud83c\uddf4",
    "+592": "\ud83c\uddec\ud83c\uddfe",
    "+593": "\ud83c\uddea\ud83c\udde8",
    "+594": "\ud83c\uddec\ud83c\uddeb",
    "+595": "\ud83c\uddf5\ud83c\uddfe",
    "+596": "\ud83c\uddf2\ud83c\uddf6",
    "+597": "\ud83c\uddf8\ud83c\uddf7",
    "+598": "\ud83c\uddfa\ud83c\uddfe",
    "+670": "\ud83c\uddf9\ud83c\uddf1",
    "+672": "\ud83c\uddf3\ud83c\uddeb",
    "+673": "\ud83c\udde7\ud83c\uddf3",
    "+674": "\ud83c\uddf3\ud83c\uddf7",
    "+675": "\ud83c\uddf5\ud83c\uddec",
    "+676": "\ud83c\uddf9\ud83c\uddf4",
    "+677": "\ud83c\uddf8\ud83c\udde7",
    "+678": "\ud83c\uddfb\ud83c\uddfa",
    "+679": "\ud83c\uddeb\ud83c\uddef",
    "+680": "\ud83c\uddf5\ud83c\uddfc",
    "+681": "\ud83c\uddfc\ud83c\uddeb",
    "+682": "\ud83c\udde8\ud83c\uddf0",
    "+683": "\ud83c\uddf3\ud83c\uddfa",
    "+685": "\ud83c\uddfc\ud83c\uddf8",
    "+686": "\ud83c\uddf0\ud83c\uddee",
    "+687": "\ud83c\uddf3\ud83c\udde8",
    "+688": "\ud83c\uddf9\ud83c\uddfb",
    "+689": "\ud83c\uddf5\ud83c\uddeb",
    "+690": "\ud83c\uddf9\ud83c\uddf0",
    "+691": "\ud83c\uddeb\ud83c\uddf2",
    "+692": "\ud83c\uddf2\ud83c\udded",
    "+850": "\ud83c\uddf0\ud83c\uddf5",
    "+852": "\ud83c\udded\ud83c\uddf0",
    "+853": "\ud83c\uddf2\ud83c\uddf4",
    "+855": "\ud83c\uddf0\ud83c\udded",
    "+856": "\ud83c\uddf1\ud83c\udde6",
    "+880": "\ud83c\udde7\ud83c\udde9",
    "+886": "\ud83c\uddf9\ud83c\uddfc",
    "+960": "\ud83c\uddf2\ud83c\uddfb",
    "+961": "\ud83c\uddf1\ud83c\udde7",
    "+962": "\ud83c\uddef\ud83c\uddf4",
    "+963": "\ud83c\uddf8\ud83c\uddfe",
    "+964": "\ud83c\uddee\ud83c\uddf6",
    "+965": "\ud83c\uddf0\ud83c\uddfc",
    "+966": "\ud83c\uddf8\ud83c\udde6",
    "+967": "\ud83c\uddfe\ud83c\uddea",
    "+968": "\ud83c\uddf4\ud83c\uddf2",
    "+970": "\ud83c\uddf5\ud83c\uddf8",
    "+971": "\ud83c\udde6\ud83c\uddea",
    "+972": "\ud83c\uddee\ud83c\uddf1",
    "+973": "\ud83c\udde7\ud83c\udded",
    "+974": "\ud83c\uddf6\ud83c\udde6",
    "+975": "\ud83c\udde7\ud83c\uddf9",
    "+976": "\ud83c\uddf2\ud83c\uddf3",
    "+977": "\ud83c\uddf3\ud83c\uddf5",
    "+992": "\ud83c\uddf9\ud83c\uddef",
    "+993": "\ud83c\uddf9\ud83c\uddf2",
    "+994": "\ud83c\udde6\ud83c\uddff",
    "+995": "\ud83c\uddec\ud83c\uddea",
    "+996": "\ud83c\uddf0\ud83c\uddec",
    "+998": "\ud83c\uddfa\ud83c\uddff",
    "+1242": "\ud83c\udde7\ud83c\uddf8",
    "+1246": "\ud83c\udde7\ud83c\udde7",
    "+1264": "\ud83c\udde6\ud83c\uddee",
    "+1268": "\ud83c\udde6\ud83c\uddec",
    "+1284": "\ud83c\uddfb\ud83c\uddec",
    "+1340": "\ud83c\uddfb\ud83c\uddee",
    "+1441": "\ud83c\udde7\ud83c\uddf2",
    "+1473": "\ud83c\uddec\ud83c\udde9",
    "+1649": "\ud83c\uddf9\ud83c\udde8",
    "+1664": "\ud83c\uddf2\ud83c\uddf8",
    "+1670": "\ud83c\uddf2\ud83c\uddf5",
    "+1671": "\ud83c\uddec\ud83c\uddfa",
    "+1684": "\ud83c\udde6\ud83c\uddf8",
    "+1758": "\ud83c\uddf1\ud83c\udde8",
    "+1767": "\ud83c\udde9\ud83c\uddf2",
    "+1784": "\ud83c\uddfb\ud83c\udde8",
    "+1849": "\ud83c\udde9\ud83c\uddf4",
    "+1868": "\ud83c\uddf9\ud83c\uddf9",
    "+1869": "\ud83c\uddf0\ud83c\uddf3",
    "+1876": "\ud83c\uddef\ud83c\uddf2",
    "+1939": "\ud83c\uddf5\ud83c\uddf7"
};

export default countryCodes;