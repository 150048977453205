import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faArrowCircleLeft,  } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const Dashboard = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [progress, setProgress] = useState(0);
    const [expire, setExpire] = useState(false);
    const [limitReached, setLimitReached] = useState(false); // Variável de controle

    useEffect(() => {
        const fetchImages = async () => {
            try {
                // Verificar se já tem 150 imagens ou se o limite foi alcançado
                if (images.length < 150 && !limitReached) {
                    const response = await axios.get('https://api-web-stories.vercel.app/random_images');
                    setImages(response.data);
                } else {
                    setLimitReached(true); // Impedir novas solicitações
                }
                setImagesLoaded(true);
            } catch (error) {
                console.error('Erro ao obter imagens:', error);
            }
        };
        if (images.length < 150){

            fetchImages();
        }
        console.log('length images', images.length);
    }, [images, limitReached]); // Adicionado "images" e "limitReached" como dependências
    useEffect(() => {
        const interval = setInterval(() => {
            setExpire(true);
        }, 15000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        setProgress(((currentImageIndex + 1) / images.length) * 100);
        
        // Alterar de imagem a cada 5 segundos
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [currentImageIndex, images]);

    const handleImageClick = (event) => {
        const { offsetX, target } = event.nativeEvent;
        const width = target.clientWidth;
        if (offsetX > width / 2) {
            handleNext();
        } else {
            handlePrevious();
        }
    };

    const handlePrevious = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const visibleImages = images.slice(currentImageIndex, progress > 3 ? currentImageIndex + 25 : currentImageIndex + 50);

    
    return (
        <div className='slider-container bg-black'>
            {imagesLoaded ? (
                <div>
                    {/* Adicionar o elemento do progress bar aqui */}
                    <div className='slider-progress progress-top' style={{ width: `${progress}%` }} />
                    
                    <div className='mt-3 col-12 image-container'>
                        <div className='image-wrapper' onClick={handleImageClick}>
                            {expire ? null : 
                                <div className=" floating-top-container">
                                    <a href='https://api.whatsapp.com/send?phone=33756992373&text=Eu+quero+comprar+Diana' target='_blank' rel='noopener noreferrer' style={{ color: 'black', textDecoration: 'none' }}>
                                        <div className='bg-light rounded' style={{ padding: '0.3rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                            <span>
                                                Aguarde 10 segundos <br />
                                                As fotos estão carregando!
                                            </span>
                                        </div>
                                    </a>
                                </div>}
                            {visibleImages.map((image, index) => (
                                <img
                                    key={index}
                                    className='slider-image'
                                    src={image}
                                    alt={`Image ${index + 1}`}
                                    style={{
                                        display: index === 0 ? 'block' : 'none',
                                    }}
                                />
                            ))}
                        </div>
                        <div className=" floating-button-container">
                            <a href='https://api.whatsapp.com/send?phone=33756992373&text=Eu+quero+comprar+Diana' target='_blank' rel='noopener noreferrer' style={{ color: 'black', textDecoration: 'none' }}>
                                <div className='bg-light rounded' style={{ padding: '0.3rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon icon={faLink} size='1x' style={{ color: 'black', marginRight: '0.3rem' }} />
                                    <span>
                                        Gostou? Tire print <br />
                                        clique aqui para comprar
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div>
                        <a href="/home">
                            <FontAwesomeIcon icon={faArrowCircleLeft} size='2x' style={{ color: 'white', marginLeft: '0.5rem' }} />
                        </a>
                    </div>
                </div>
            ) : (
                <div className="loading">Carregando, aguarde...</div>
            )}
        </div>
    );
}

export default Dashboard;
